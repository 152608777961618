@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

/* login and register css*/
.loginReg {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #c2c2c2;
  border-radius: 25px;
}
.loginReg h1{
  color: #b235f1;
}
.loginReg h2{
  color: #f1359c;
}

.loginReg .registration{
  display: flex;
  flex-direction: column;
}

.loginReg .login{
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="password"],
input[type="number"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
}

.loginReg button {
  width: 100%;
  margin: 10px auto;
  background-color: #af4ca0;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.loginReg button:hover {
  background-color: #8e3e56;
}
.setting-button {
  width: 100%;
  margin: 10px auto;
  background-color: #af4ca0;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}
.setting-button:hover {
  background-color: #8e3e56;
}
.error-msg {
  text-align: right;
}
/* navbar */
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  border-bottom: 2px solid #c2c2c2;
}
.navbar h1 {
  color: #b235f1;
}
.navbar .links {
  margin-left: auto;
}

.navbar .username{
  color:  white;
  background-color: #b235f1;
  margin-left: 14px;
  margin-right: 14px;
  text-decoration: none;
  padding: 6px;
  border-radius: 10px;
  transition: all 0.3s;
  white-space: nowrap;
}
.navbar .username:hover {
  color:  #f1356d;
  background-color: #f1f1f1;
}

.navbar .type{
  margin-right: 16px;
  text-decoration: none;
  padding: 6px;
  border-radius: 10px;
  font-weight: bold;
  transition: all 0.3s;
}
.navbar .type:hover{
  color: #f1356d;
  background-color: #f1f1f1;
}


.navbar .logout-button {
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.3s;
  text-align: center;
}

.navbar .logout-button:hover {
  color: #f1356d;
  background-color: #f1f1f1;
}
.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

/* home*/
.home{
  margin-top: -55px;
}
/* filer and sort*/

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.category-select {
  border-radius: 20px; /* Adjust this value to change the roundness of the corners */
  appearance: none; /* Remove default styling on some browsers */
  padding: 4px 8px;
  border: 1px solid #ccc;
  font-weight: bold;
}

.category-select:hover{
  color: #f1356d;
  background-color: #f1f1f1;
}
.filter-bar {
  margin-right: 20px; /* Add some space between filter-bar and sort-button */
}

.sort-button {
  border-radius: 20px; /* Adjust this value to change the roundness of the corners */
  appearance: none; /* Remove default styling on some browsers */
  padding: 4px 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  font-weight: bold;

}

.sort-button:hover {
  color: #f1356d;
  background-color: #f1f1f1;
}

/* post previews / list */
.post-list{
  text-decoration: none;
}
.post-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border: 1px solid #ddd; /* Add border */
  border-radius: 10px; /* Optional: Add border radius */
  transition: box-shadow 0.3s; /* Add transition for hover effect */
  word-wrap: break-word;
}

.post-preview:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.post-preview h2 {
  font-size: 20px;
  color: #f13593;
  margin-bottom: 8px;
}

.preview-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.preview-info p {
  margin: 4px 0;
}
.price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.price {
  margin-right: 10px;
  font-weight: bold;
}

/*page bar*/

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.3s;
  text-align: center;
}

.pagination button:hover {
  color: #f1356d;
  background-color: #f1f1f1;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-weight: bold;
}
/*create post*/
.create-post-filter {
  display: flex;
  justify-content: space-between;
}
/*post-details*/
.post-box {
  border: 1px solid #b235f1; /* Add border */
  border-radius: 10px;
  margin-top: -45px;
}
.post-details{
  word-wrap: break-word;
}
.post-user{
  padding: 10px 16px;
  border: 1px solid #ddd; /* Add border */
  border-radius: 10px; 
}
.user-box{
  padding: 5px 5px;
  margin: 5px 0;
  border: 1px solid #b235f1; /* Add border */
  color: #340043;
  border-radius: 10px; 
  display: inline-block;
  font-weight: bold;
}
.title{
  color: #f1356d;
}
.post-content {
  max-width: 600px; /* Adjust this to the desired maximum width */
  padding: 10px 16px;
}

.post-content img {
  max-width: 100%;
  border-radius: 10px;
}

.post-remove {
  display: flex;
  align-items: center;
}
.post-remove button {
  border: 1px solid #b235f1; /* Add border */
  color: #340043;
  background-color: transparent;
  border-radius: 10px; 
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.post-remove button:hover {
  background-color: #ddd;
  color: #f13593;
}
.reply-remove{
  display: flex;
  align-items: center;
}
.reply-remove button{
  border: 1px solid #f13593; /* Add border */
  color: #340043;
  background-color: transparent;
  border-radius: 10px; 
  display: inline-block;
  font-weight: bold;
  margin-right: 11px;
}
.reply-remove button:hover{
  background-color: #ddd;
  color: #b235f1;
}
/*rich text*/
.quill-box{
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
}
.quill-box .ql-editor {
  min-height: 130px; /* 4 lines * 20px each */
}
.quill-submit{
  width: 100%;
  margin: 10px auto;
  background-color: #af4ca0;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.quill-submit:hover{
  background-color: #ddd;
  color: #f13593;
}
/*replies*/
.reply{
  border: 1px solid #f13593; /* Add border */
  border-radius: 10px;
  margin-top: 5px;
}
.reply-user{
  padding: 10px 16px;
  border: 1px solid #ddd; /* Add border */
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.reply-user p{
  margin-right: 10px;
}

.reply-user-box{
  padding: 5px 5px;
  margin: 5px 0;
  border: 1px solid #f13593; /* Add border */
  color: #340043;
  border-radius: 10px; 
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}


